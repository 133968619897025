$primaryColor: #2876D0;
$primaryColorOverlay: rgba(40, 118, 208, 0.12);
$secondaryColor: #0F3551;
$accentColor: #E4032E;
$grayDarkColor: #0F3551;

$logoGradient: linear-gradient(45deg, $primaryColor 30%, $primaryColor);

$colorBeige: #e6e2e2;
$colorGrey: rgba(232, 232, 232, 0.5);

$logoBackground: url("/images/logo/cloud-transparent-mono.svg") center / contain no-repeat;
$logoBackgroundTablet: url("/images/logo/cloud-transparent-mono-48-48.svg") center / contain no-repeat;
$logoBackgroundLanding: url("/images/logo/cloud-transparent-mono-long.svg") center / contain no-repeat;
$logoBackgroundMobile: url("/images/logo/brb-logo-mono-mobile.svg") center / contain no-repeat;
$navbarLogo: url("/images/logo/navbar-cloud-logo.svg") center / contain no-repeat;

aside.nav-sidebar{
    .sc-branding{
        padding: 0;
        .cloud-logo{
            width: 100%;
            margin: 0;
            padding: 30px;
        }
        .sc-title {
            display: none;
        }
    }

    @media (max-width: 991px) {
        .sc-branding{
            padding: 0;
            .cloud-logo{
                background: $logoBackgroundTablet;
            }
        }
    }
}
body {
    header.top-margin {
        margin-top: 0;
    }
    header {


        .navbar-light {
            #title {
                padding: 0 !important;
            }

            .cloud-logo {
                background: $logoBackgroundLanding !important;
                margin-right: 0 !important;
                margin-left: -5rem !important;
                height: 3.5rem !important;
                width: 21.5rem !important;
            }

            @media (max-width: 991px) {
               .cloud-logo {
                    background: $logoBackgroundTablet;
                    margin-left: 0.5em!important;
                    position: absolute;
                    left: 0;
                    width: 208px !important;
                }
            }
            @media (max-width: 557px) {
                .cloud-logo {
                    background: $logoBackgroundMobile !important;
                    width: 112px !important;
                    height: 50px;
                    margin-top: -8px;
                 }
             }
        }
    }
}

.section-imprint.loggedout ul {
	list-style-type: none!important;
	margin: 0!important;
}

.made-with-love {
    .poweredby-logo {
        height: 1em;
        vertical-align: middle;
    }
}

.primary-color{
    color: $primaryColor;
    display: none;
}
.secondary-color {
    color: $secondaryColor;
    display: none;
}
@import "./alerts.scss";
@import "./colors";
@import "./bootstrap/scss/bootstrap-flex";

.input-group-addon {
	width: auto;
}

//TODO in BC-3743: media code needs to be changed and refactored in terms of implementation BC-3743
.alert-button {
	.dropdown-menu {
		left: -20.7rem !important;

			.dropdown-menu::before,
			.dropdown-menu::after {
				content: none !important;
			}
		}
}

.navbar-nav {

	@include media-breakpoint-down(md) {
		height: 56px;
  }

	@include media-breakpoint-down(sm) {
		height: 56px;
  }
	.minimal-button {
		float: right;
		margin-top: 8px;
		padding-right: 10px;
		.dropdown-menu {
			&:after,
			&:before {
				display: none;
			}

			@include media-breakpoint-down(sm) {
				left: 19.75rem;
			}
		}
	}
}
